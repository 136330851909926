import { FunctionComponent } from "react";

import {
  Option,
  OptionsSelect,
  OptionsSelectProps,
  Persona,
  PersonaSize
} from "@bps/fluent-ui";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useSearchUsers } from "@libs/api/gateways/plt/plt-gateway.hooks";

export interface UsersSelectProps extends Omit<OptionsSelectProps, "options"> {
  tenantId?: string;
}

const NO_USER_NAME = "No user name!";
const getUserName = (data: PltUser | undefined) => {
  if (!data) return NO_USER_NAME;
  if (!data.firstName && !data.lastName) {
    if (!data.username) return NO_USER_NAME;
    return data.username;
  }

  let name = `${data.firstName ?? ""} ${data.lastName ?? ""}`;
  if (data.title) {
    name += ` ,${data.title}`;
  }

  return name;
};
export const UsersSelect: FunctionComponent<UsersSelectProps> = ({
  tenantId,
  ...rest
}) => {
  const { isLoading, error, data } = useSearchUsers({ tenantId, ...rest });
  const users = data ?? [];

  const options: Option<PltUser>[] = users.map(data => ({
    key: data?.id,
    text: getUserName(data)
  }));

  return (
    <OptionsSelect
      options={options}
      errorMessage={error?.message}
      loading={isLoading}
      onRenderOption={(option: Option<PltUser>) => (
        <Persona id={option.key} size={PersonaSize.size24} text={option.text} />
      )}
      styles={{ root: { width: 250 } }}
      {...rest}
    />
  );
};
